<template>
    <div>
        <dialog class="block relative w-full z-50 p-0" v-if="authModalShown">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button v-if="authForm && authForm.step == 'initial'" @click="authModalShown = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <button v-if="authForm && authForm.step != 'initial'" @click="authForm.step = 'initial'" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-angle-small-left text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span v-if="authForm && authForm.step === 'initial'">Log in or sign up</span>
                                    <span v-if="authForm && authForm.step === 'signUp'">Finish signing up</span>
                                    <span v-if="authForm && authForm.step === 'passwordLogin'">Sign in</span>
                                </div>
                            </div>

                            <AuthForm ref="authForm" class="w-full p-5" />

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, provide } from 'vue'
    import { useAuth } from '../../lib/auth';
    import AuthForm from './AuthForm.vue';

    export default {
	    props: [],
        components: {
            AuthForm
        },
	    setup(props) {
            const { authModalShown } = useAuth()
            const authForm = ref(null)

            return {
                authModalShown,
                authForm
            }
		}
	}
</script>
