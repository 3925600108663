<template>
    <div v-bind:class="{'absolute h-0': scrollPosition > 0 && !expand_search_bar}">
        <div class="mx-auto flex items-center justify-between transition-all ease-in-out duration-200" v-bind:class="{'w-full translate-y-0 py-3': scrollPosition === 0 || expand_search_bar, 'xl:w-1/3 lg:w-2/5 -translate-y-16 cursor-pointer border rounded-lg hover:shadow hover:cursor-pointer': scrollPosition > 0 && !expand_search_bar}" @click="$emit('expand', true)">

            <div v-if="scrollPosition === 0 || expand_search_bar" class="flex space-x-5 w-full h-12">
                <div class="md:w-2/5 w-4/5 h-full">
                    <div class="flex items-center bg-white divide-x border h-full" v-bind:class="{'rounded-lg': results.length == 0, 'rounded-t-lg lg:rounded-br-lg': results.length > 0}">
                        <div class="lg:w-3/4 w-full relative h-full">
                            <div class="flex items-center h-full">
                                <input ref="searchQueryInput" type="text" class="bg-white flex-1 px-3 focus:outline-none rounded-lg h-full" :placeholder="(usingCurrentLocation)? $t('search_bar.search_current_location_place_holder') : $t('search_bar.search_placeholder')" v-model="searchQuery.q" v-on:keyup.enter="executeSearch" />
                                <button v-if="!loading" class="flex items-center p-3" @click="getCurrentLocation"><i class="fi fi-rr-location-crosshairs flex items-center"></i></button>
                                <loading v-else class="px-1" />
                            </div>
                        </div>

                        <div class="lg:flex w-1/4 p-3 space-x-3 hidden">
                            <div class=" text-gray-400">{{ $t('search_bar.radius') }}</div>
                            <select class="focus:outline-none bg-white" v-model="searchQuery.radius">
                                <option value="0">0 km</option>
                                <option value="1000">1 km</option>
                                <option value="2000">2 km</option>
                                <option value="5000">5 km</option>
                                <option value="10000">10 km</option>
                                <option value="15000">15 km</option>
                                <option value="30000">30 km</option>
                                <option value="50000">50 km</option>
                                <option value="100000">100 km</option>
                            </select>
                        </div>
                    </div>

                    <div class="lg:w-3/4 w-full relative -mt-[1px]" v-if="results.length > 0">
                        <div class="absolute border-b border-x shadow-lg rounded-b-lg w-full bg-white overflow-hidden mt-[1px]">
                            <ul class="bg-white">
                                <li v-for="result in results" class="hover:bg-gray-100 hover:cursor-pointer p-2" @click="selectPlace(result)">
                                    {{ result.description }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="w-2/5 md:flex space-x-5 hidden">
                    <div class="w-4/5 flex items-center bg-white rounded-lg divide-x border overflow-hidden">
                        <div class="flex items-center w-1/2 space-x-1 h-full">
                            <div class="px-2 flex-none">{{ $t('search_bar.from') }}</div>

                            <div class="w-8 flex-none text-center">
                                {{  preferences.currency.symbol_icon }}
                            </div>
                            <price-input class="p-3 w-full focus:outline-none h-12" type="text" :placeholder="$t('search_bar.from_placeholder')" v-model="searchQuery.price.from" v-on:keyup.enter="executeSearch" />
                        </div>
                        <div class="flex items-center w-1/2 space-x-1 h-full">
                            <div class="px-2 flex-none">{{ $t('search_bar.to') }}</div>

                            <div class="w-8 flex-none text-center">
                                {{  preferences.currency.symbol_icon }}
                            </div>
                            <price-input class="p-3 w-full focus:outline-none h-12" type="text" :placeholder="$t('search_bar.to_placeholder')" v-model="searchQuery.price.to" v-on:keyup.enter="executeSearch" />
                        </div>
                    </div>

                    <div class="relative w-1/5 h-full">
                        <div v-if="amountOfActiveFilters > 0" class="absolute bg-primary text-white rounded-full w-5 h-5 flex items-center justify-center text-xs -right-2 -top-2">
                            {{ amountOfActiveFilters }}
                        </div>
                        <button class="w-full h-full bg-white hover:shadow-md duration-150 rounded-lg border hover:cursor-pointer" @click="showSearchFilterModal = !showSearchFilterModal">
                            <i class="fi fi-rr-settings-sliders flex items-center justify-center"></i>
                        </button>
                    </div>
                </div>

                <div class="w-1/5 h-full">
                    <button class="bg-primary text-white w-full h-full rounded-lg font-bold flex items-center justify-center space-x-3" @click="executeSearch">
                        <i class="fi fi-rr-search flex items-center"></i>
                        <div class="md:block hidden">{{ $t('search_bar.search') }}</div>
                    </button>
                </div>
            </div>

            <div v-else class="flex justify-between h-12 w-full">
                <div class="py-1 w-2/5 px-2">
                    <h3 class="text-xs font-semibold text-gray-400">{{ $t('search_bar.location') }}</h3>
                    <div v-if="searchQuery.selected_place" class="font-bold overflow-hidden h-5">{{ searchQuery.selected_place.description }}</div>
                    <div v-else class="text-sm font-semibold">{{ $t('search_bar.anywhere') }}</div>
                </div>
                <div class="w-2/5 py-1 px-2 lg:block hidden">
                    <h3 class="text-xs font-semibold text-gray-400">{{ $t('search_bar.price') }}</h3>
                    <div>
                        <span class="text-sm font-semibold" v-if="!searchQuery.price.from && !searchQuery.price.to">{{ $t('search_bar.any_price') }}</span>
                        <span v-if="searchQuery.price.from > 0 && !searchQuery.price.to" class="text-sm">{{ $t('search_bar.starting_from') }} <span class="font-semibold">{{ price(searchQuery.price.from, preferences.currency) }}</span></span>
                        <span v-if="!searchQuery.price.from && searchQuery.price.to > 0" class="text-sm">{{ $t('search_bar.max_price') }} <span class="font-semibold">{{ price(searchQuery.price.to, preferences.currency) }}</span></span>
                        <span v-if="searchQuery.price.from > 0 && searchQuery.price.to > 0" class="text-sm"><span class="font-semibold">{{ price(searchQuery.price.from, preferences.currency) }} - {{ price(searchQuery.to_price, preferences.currency) }}</span></span>
                    </div>
                </div>
                <button class="bg-primary text-white h-full w-1/5 rounded-lg flex justify-center items-center">
                    <i class="fi fi-rr-search flex items-center"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import { useSearch } from '../lib/search';
    import { useApi } from '../lib/api';
    import { useDebounceFn } from '@vueuse/core'
    import { usePreferences } from '../lib/preferences';
    import { useHelpers } from '../lib/helpers';
    import PriceInput from './PriceInput.vue'

    export default {
	    props: ['scroll_position', 'expand_search_bar'],
        components: {
            PriceInput
        },
        watch: {
            '$props.scroll_position'(value) {
                this.scrollPosition = value
            },
            'searchQueryInput'(value) {
                if(value && this.$props.expand_search_bar) {
                    value.focus()
                }
            },
            'searchQuery.q'(value) {
                if((this.searchQuery.selected_place === '') || (this.searchQuery.selected_place && this.searchQuery.selected_place.description != value)) {
                    this.usingCurrentLocation = false

                    this.searchAutocomplete()
                }
            }
        },
	    setup(props, { emit }) {
            const scrollPosition = ref(props.scroll_position ?? 0)
            const { get, data, loading } = useApi('google/maps/places/autocomplete')
            const { showSearchFilterModal, searchQuery, executeSearch, amountOfActiveFilters } = useSearch()
            const { preferences } = usePreferences()
            const { price } = useHelpers()
            const searchQueryInput = ref(null)

            const results = ref([])
            const usingCurrentLocation = ref(false)

            const searchAutocomplete = useDebounceFn(() => {
                results.value = []

                get({
                    input: searchQuery.value.q
                }).then(() => {
                    if(data.value.status && data.value.results.status == 'OK') {
                        results.value = data.value.results.predictions
                    }
                })
            }, 1000)

            const selectPlace = (place) => {
                searchQuery.value.q = place.description
                searchQuery.value.selected_place = place

                results.value = []
            }

            const getCurrentLocation = () => {
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        usingCurrentLocation.value = true

                        searchQuery.value.coordinates = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                    })
                }
            }

			return {
                scrollPosition,
                showSearchFilterModal,
                searchQuery,
                searchAutocomplete,
                results,
                loading,
                selectPlace,
                getCurrentLocation,
                executeSearch,
                usingCurrentLocation,
                preferences,
                price,
                searchQueryInput,
                amountOfActiveFilters
			}
		}
	}
</script>

