<template>
    <div>
        <dialog class="block relative w-full z-50 p-0" v-if="showSearchFilterModal">
            <div class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50">
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="flex flex-col items-start w-full">
                            <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                                <button @click="showSearchFilterModal = false" class="w-8 h-8 hover:bg-gray-100 rounded-full flex justify-center items-center">
                                    <i class="fi fi-rr-cross-small text-xl flex items-center"></i>
                                </button>

                                <div class="text-center text-lg font-bold w-full">
                                    <span>Filter</span>
                                </div>
                            </div>

                            <div class="w-full space-y-3 px-5 divide-y">
                                <div class="space-y-5 py-5">
                                    <div>
                                        <h3 class="font-bold text-xl">Availability</h3>
                                        <p class="text-gray-400 text-sm">Select for home availability, under contract or sold</p>
                                    </div>

                                    <div class="flex space-x-5 px-5">
                                        <button class="w-1/3 py-3 border rounded-lg font-semibold" v-bind:class="{'border-primary border-2': searchQuery.status === 'available', 'hover:border-gray-400': searchQuery.status !== 'available'}" @click="(searchQuery.status === 'available')? searchQuery.status = null : searchQuery.status = 'available'">Available</button>
                                        <button class="w-1/3 py-3 border rounded-lg font-semibold" v-bind:class="{'border-primary border-2': searchQuery.status === 'under_contract', 'hover:border-gray-400': searchQuery.status !== 'under_contract'}" @click="(searchQuery.status === 'under_contract')? searchQuery.status = null : searchQuery.status = 'under_contract'">Under Contract</button>
                                        <button class="w-1/3 py-3 border rounded-lg font-semibold" v-bind:class="{'border-primary border-2': searchQuery.status === 'sold', 'hover:border-gray-400': searchQuery.status !== 'sold'}" @click="(searchQuery.status === 'sold')? searchQuery.status = null : searchQuery.status = 'sold'">Sold</button>
                                    </div>
                                </div>

                                <div class="space-y-5 py-5">
                                    <div class="space-y-5">
                                        <div>
                                            <h3 class="font-bold text-xl">Living Area (m2)</h3>
                                            <p class="text-gray-400 text-sm">Enter your prefered value in m2</p>
                                        </div>

                                        <div class="flex w-1/2 items-center">
                                            <input type="number" placeholder="50m2" class="border rounded-lg p-3 w-2/5" v-model="searchQuery.range.features.areas.living_area.from" />
                                            <div class="px-5">to</div>
                                            <input type="number" placeholder="3,000m2" class="border rounded-lg p-3 w-2/5" v-model="searchQuery.range.features.areas.living_area.to" />
                                        </div>
                                    </div>

                                    <div class="space-y-5">
                                        <div>
                                            <h3 class="font-bold text-xl">Plot Area (m2)</h3>
                                            <p class="text-gray-400 text-sm">Enter your prefered value in m2</p>
                                        </div>
                                        <div class="flex w-1/2 items-center">
                                            <input type="number" placeholder="50m2" class="border rounded-lg p-3 w-2/5" v-model="searchQuery.range.features.areas.plot_size.from" />
                                            <div class="px-5">to</div>
                                            <input type="number" placeholder="3,000m2" class="border rounded-lg p-3 w-2/5" v-model="searchQuery.range.features.areas.plot_size.to" />
                                        </div>
                                    </div>

                                </div>

                                <div class="space-y-5 py-5">
                                    <div>
                                        <h3 class="font-bold text-xl">Rooms and Bedrooms</h3>
                                        <p class="text-gray-400 text-sm">Select for home availability, under contract or sold</p>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Rooms</h4>
                                        <div class="space-x-3">
                                            <button class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.rooms === null, 'border text-black hover:border-secondary': searchQuery.rooms !== null }" @click="searchQuery.rooms = null">Any</button>
                                            <button v-for="x in 7" class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.rooms === x, 'border text-black hover:border-secondary': searchQuery.rooms !== x }" @click="searchQuery.rooms = x">
                                                <span v-if="x === 7">{{ x }}+</span>
                                                <span v-else>{{ x }}</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Bedrooms</h4>

                                        <div class="space-x-3">
                                            <button class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.bedrooms === null, 'border text-black hover:border-secondary': searchQuery.bedrooms !== null }" @click="searchQuery.bedrooms = null">Any</button>
                                            <button v-for="x in 7" class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.bedrooms === x, 'border text-black hover:border-secondary': searchQuery.bedrooms !== x }" @click="searchQuery.bedrooms = x">
                                                <span v-if="x === 7">{{ x }}+</span>
                                                <span v-else>{{ x }}</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Bathrooms</h4>

                                        <div class="space-x-3">
                                            <button class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.bathrooms === null, 'border text-black hover:border-secondary': searchQuery.bathrooms !== null }" @click="searchQuery.bathrooms = null">Any</button>
                                            <button v-for="x in 7" class="rounded-lg px-5 py-2" v-bind:class="{'bg-secondary text-white': searchQuery.bathrooms === x, 'border text-black hover:border-secondary': searchQuery.bathrooms !== x }" @click="searchQuery.bathrooms = x">
                                                <span v-if="x === 7">{{ x }}+</span>
                                                <span v-else>{{ x }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="space-y-5 py-5">
                                    <div>
                                        <h3 class="font-bold text-xl">Type of construction</h3>
                                        <p class="text-gray-400 text-sm">Select type of construction</p>
                                    </div>

                                    <div class="flex space-x-5 px-5">
                                        <button class="w-1/2 py-3 border rounded-lg font-semibold" v-bind:class="{'border-primary border-2': searchQuery.construction_type === 'new', 'hover:border-gray-400': searchQuery.construction_type !== 'new'}" @click="(searchQuery.construction_type === 'new')? searchQuery.construction_type = null : searchQuery.construction_type = 'new'">New construction</button>
                                        <button class="w-1/2 py-3 border rounded-lg font-semibold" v-bind:class="{'border-primary border-2': searchQuery.construction_type === 'existing', 'hover:border-gray-400': searchQuery.construction_type !== 'existing'}" @click="(searchQuery.construction_type === 'existing')? searchQuery.construction_type = null : searchQuery.construction_type = 'existing'">Existing construction</button>
                                    </div>
                                </div>

                                <div class="space-y-5 py-5">
                                    <div>
                                        <h3 class="font-bold text-xl">Features & Amenities</h3>
                                        <p class="text-gray-400 text-sm">Select type of construction</p>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Gated Resort</h4>

                                        <div class="flex space-x-16 text-sm">
                                            <div class="flex space-x-3 items-center">
                                                <input id="gatedResortYes" type="checkbox" class="w-5 h-5 accent-primary" value="yes" v-model="searchQuery.features.gated_resort" />
                                                <label for="gatedResortYes" class="hover:cursor-pointer">Yes</label>
                                            </div>

                                            <div class="flex space-x-3 items-center">
                                                <input id="gatedResortNo" type="checkbox" class="w-5 h-5 accent-primary" value="no" v-model="searchQuery.features.gated_resort" />
                                                <label for="gatedResortNo" class="hover:cursor-pointer">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Pool</h4>

                                        <div class="flex space-x-16 text-sm">
                                            <div class="flex space-x-3 items-center">
                                                <input id="poolYes" type="checkbox" class="w-5 h-5 accent-primary" value="yes" v-model="searchQuery.features.pool" />
                                                <label for="poolYes" class="hover:cursor-pointer">Yes</label>
                                            </div>

                                            <div class="flex space-x-3 items-center">
                                                <input id="poolShared" type="checkbox" class="w-5 h-5 accent-primary" value="shared" v-model="searchQuery.features.pool" />
                                                <label for="poolShared" class="hover:cursor-pointer">Shared</label>
                                            </div>

                                            <div class="flex space-x-3 items-center">
                                                <input id="poolNo" type="checkbox" class="w-5 h-5 accent-primary" value="no" v-model="searchQuery.features.pool" />
                                                <label for="poolNo" class="hover:cursor-pointer">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="space-y-3">
                                        <h4 class="">Furnished</h4>

                                        <div class="flex space-x-16 text-sm">
                                            <div class="flex space-x-3 items-center">
                                                <input id="furnishedYes" type="checkbox" class="w-5 h-5 accent-primary" value="yes" v-model="searchQuery.features.furnished" />
                                                <label for="furnishedYes" class="hover:cursor-pointer">Yes</label>
                                            </div>

                                            <div class="flex space-x-3 items-center">
                                                <input id="furnishedNo" type="checkbox" class="w-5 h-5 accent-primary" value="no" v-model="searchQuery.features.furnished" />
                                                <label for="furnishedNo" class="hover:cursor-pointer">No</label>
                                            </div>

                                            <div class="flex space-x-3 items-center">
                                                <input id="furnishedInConsultation" type="checkbox" class="w-5 h-5 accent-primary" value="in_consultation" v-model="searchQuery.features.furnished" />
                                                <label for="furnishedInConsultation" class="hover:cursor-pointer">In Consultation</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="w-full md:px-5 md:py-5 p-3 border-t flex justify-between">
                                <button @click="clearSearchQuery()">Clear All</button>

                                <button class="bg-primary text-white p-3 rounded-lg font-semibold" @click="showSearchFilterModal = false">Apply Filters</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
	import { ref, inject } from 'vue'
    import { useSearch } from '../lib/search';

    export default {
	    props: [],
        components: {

        },
	    setup(props) {
            const { showSearchFilterModal, searchQuery, clearSearchQuery } = useSearch()

            return {
                showSearchFilterModal,
                searchQuery,
                clearSearchQuery
            }
		}
	}
</script>
