import { ref } from 'vue'
import { useApi } from './api';

const user = ref(null)
const listingFavourites = ref(null)

const getUser = (() => {
    const { get, data } = useApi('me')

    get().then(() => {
        if(data.value.status) {
            user.value = data.value.user
        }
    }).catch(() => {

    })
})

const getListingFavourites = (() => {
    const { get, data } = useApi('me/listing/favourites')

    get().then(() => {
        if(data.value.status) {
            listingFavourites.value = data.value.listing_favourites
        }
    }).catch(() => {

    })
})



getUser()

export const useUser = () => {
    return {
        user,
        listingFavourites,
        getUser,
        getListingFavourites
    }
}
