<template>
    <div class="bg-white md:block hidden">
        <div class="2xl:w-3/5 xl:w-5/6 mx-auto relative">
            <div class="flex items-center justify-between py-3 xl:px-0 px-5">
                <a href="/">
                    <img src="/images/logo.svg" class="w-40" />
                </a>

                <ul class="flex space-x-3 items-center">
                    <li>
                        <a v-if="user" href="/listing/new" class="lg:px-8 px-5 py-3 hover:text-gray-900 font-semibold border border-dark rounded-lg text-black hover:shadow-md duration-150 whitespace-nowrap">
                            {{ $t('menu.post_property') }}
                        </a>
                        <button v-else @click="showAuthModal('/listing/new')" class="lg:px-8 px-5 py-3 hover:text-gray-900 font-semibold border border-dark rounded-lg text-black hover:shadow-md duration-150 whitespace-nowrap">
                            {{ $t('menu.post_property') }}
                        </button>
                    </li>
                    <li>
                        <button @click="$root.$refs.localeSelectModal.openModal()" class="text-gray-500 px-5 py-3 hover:rounded-full hover:bg-gray-100 hover:text-gray-900">
                            <i class="fi fi-rr-globe flex items-center"></i>
                        </button>
                    </li>
                    <li>
                        <UserMenu></UserMenu>
                    </li>
                </ul>
            </div>

            <SearchBar v-if="noSearch !== true" scroll_position="1" :expand_search_bar="expandSearchBar" @expand="changeExpandState" class="w-full"></SearchBar>
        </div>
    </div>
</template>

<script>
	import { ref } from 'vue'
    import SearchBar from './SearchBar.vue';
    import UserMenu from './UserMenu.vue'
    import { useUser } from '../lib/user'
    import { useAuth } from '../lib/auth'

	export default {
    props: ['noSearch'],
    components: {
        SearchBar,
        UserMenu
    },
    setup(props) {
        const { user } = useUser()
        const { showAuthModal } = useAuth()
        const expandSearchBar = ref(false)

        const scrollPosition = ref(0);

        const changeExpandState = (value) => {
            expandSearchBar.value = value
        }

        return {
            scrollPosition,
            user,
            showAuthModal,
            expandSearchBar,
            changeExpandState
        };
    },
        mounted() {
            var vm = this

            window.onscroll = function(){
                var B = document.body; //IE 'quirks'
                var D = document.documentElement; //IE with doctype
                D = (D.clientHeight)? D: B;

                if((vm.scrollPosition - D.scrollTop) < -15 || (vm.scrollPosition - D.scrollTop) > 15) {
                    vm.expandSearchBar = false
                }
            };
        }
}
</script>
